import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`欢迎来到 Pipy 文档。`}</p>
    <p>{`Pipy 是面向云、边缘和 IoT 的可编程网络代理。`}</p>
    <h1 {...{
      "id": "学习资料"
    }}>{`学习资料`}</h1>
    <p>{`这些说明可以帮助了解以及如何使用 Pipy。`}</p>
    <h2 {...{
      "id": "pipy-是什么"
    }}>{`Pipy 是什么？`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/intro/overview"
        }}>{`概述`}</a>{` - 本节介绍 Pipy 是什么以及为什么要选择 Pipy。`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/intro/concepts"
        }}>{`概念`}</a>{` - 本节详细介绍 Pipy 的架构概念。`}</li>
    </ul>
    <h2 {...{
      "id": "开始"
    }}>{`开始`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/quick-start"
        }}>{`快速开始`}</a>{` - 快速开始使用 Pipy。`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/build-install"
        }}>{`安装`}</a>{` - 如何从头开始构建和安装 Pipy。`}</li>
    </ul>
    <h2 {...{
      "id": "学习"
    }}>{`学习`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorial/01-hello"
        }}>{`教程`}</a>{` 通过示例应用逐步详解。`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api"
        }}>{`API 参考`}</a>{` - Pipy API 的文档。`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/pjs"
        }}>{`PipyJS 参考`}</a>{` - PipyJS 的文档。PipyJS 是用于 Pipy 的简化版 JavaScript。`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://katacoda.com/flomesh-io"
        }}>{`Katacoda 场景`}</a>{` 适合初学者和进阶学者的交互式学习。`}</li>
    </ul>
    <h2 {...{
      "id": "贡献"
    }}>{`贡献`}</h2>
    <p>{`如果想深入 Pipy 的开发，这些材料可以提供一些指导。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/getting-help"
        }}>{`贡献指南`}</a>{` - 关于如何创建 issue 并向 Pipy 项目贡献代码的指南。`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/flomesh-io/pipy/blob/main/CODE_OF_CONDUCT.md"
        }}>{`行为准则`}</a>{` - 社区合作的标准和期望。`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      